import { useEffect, useState } from "react";
import {
  Heading,
  Content,
  Footer,
  Well,
  LabeledValue,
  ContextualHelp,
  IllustratedMessage,
  ProgressCircle,
  Flex,
  Divider,
  Text,
} from "@adobe/react-spectrum";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import IllustratedFlash from "@spectrum-icons/workflow/Heal";
import UserListView from "../../components/MemberData/UserListView";
import { useAsyncProfiles } from "../../services/rpc_supabase/bucket";
import { useMarkAllFlashesAsRead } from "../../services/rpc_supabase/flashs";

function LoadingSpinner() {
  return (
    <Flex alignItems="center" justifyContent="center" marginTop="size-100" width="100%" height="100%">
      <ProgressCircle aria-label="En cours" isIndeterminate />
    </Flex>
  );
}

function EmptyState({ error }) {
  return (
    <IllustratedMessage maxWidth="100%" margin="size-400">
      <IllustratedFlash size="L" aria-label="courrier" />
      <Heading>{error ? "Une erreur est survenue" : "Y'a pas de Flash, hélas !"}</Heading>
      <ContextualHelp variant="help">
        <Heading>En attendant un peu de culture... 🥕</Heading>
        <Content>
          Un chapitre fort intéressant sur les artichauts.
          <Well marginY="size-100">
            "Pour sûr, les artichauts sont de volages amoureux : point ne se « fixent » et ils adorent « jouer ». On
            dirait volontiers qu’ils ont un « coeur d’artichaut ! De semis ou de bouture, la reproduction de l’artichaut
            est une aventure."
          </Well>
        </Content>
        <Footer>
          <LabeledValue label="Selon Mr. Claude Foury, auteur." value="Histoire de Légumes. INRA" />
        </Footer>
      </ContextualHelp>
      <Content>Tramway • courant alternatif ®</Content>
    </IllustratedMessage>
  );
}

export default function Flash() {
  const isOwnProfile = false;
  const isLiked = false;
  const isVisited = true;
  const isFlahed = false;

  const { secureList, isLoading } = useAsyncProfiles(isOwnProfile, isLiked, isVisited, isFlahed);
  const { markAllFlashesAsRead, isLoading: isMarkingRead, error: markError } = useMarkAllFlashesAsRead();
  const [markAsReadError, setMarkAsReadError] = useState(null);

  useEffect(() => {
    const handleMarkAsRead = async () => {
      try {
        await markAllFlashesAsRead();
        setMarkAsReadError(null);
      } catch (err) {
        console.error(err);
        setMarkAsReadError(err.message);
      }
    };
    handleMarkAsRead();
  }, [markAllFlashesAsRead]);

  if (isLoading || isMarkingRead) {
    return <LoadingSpinner />;
  }

  if (markError || !secureList?.items?.length) {
    return <EmptyState error={markError || markAsReadError} />;
  }

  return (
    <PrivateLayout>
      <Text>Quand quelqu'un s'intéresse à toi, il apparaît ici. Amuse-toi bien !</Text>
      <Divider size="S" marginY="size-250" />
      <UserListView secureList={secureList} />
    </PrivateLayout>
  );
}
