import { useState } from "react";
import { uploadAvatar } from "./uploadAvatar";

export const useUploadAvatar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUpload = async (bucket, fileName, file) => {
    setIsLoading(true);
    setError(null);
    try {
      const result = await uploadAvatar(bucket, fileName, file);
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { handleUpload, isLoading, error };
};
