import { useState, useCallback } from "react";
import {
  Divider,
  Dialog,
  Flex,
  Heading,
  ActionButton,
  Button,
  ToggleButton,
  Content,
  Grid,
  Avatar,
  View,
  Text,
  Well,
  IllustratedMessage,
} from "@adobe/react-spectrum";

import Send from "@spectrum-icons/workflow/Send";
import Heart from "@spectrum-icons/workflow/Heart";
import Add from "@spectrum-icons/workflow/Add";

function UserCard({ item }) {
  console.log(item);

  return (
    <View
      UNSAFE_style={{
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 6px 20px 0px",
      }}
      overflow="hidden"
      width={{ base: "100%", S: "100%", M: "42%", L: "40%", XL: "42%" }}
      height="size-2600"
      padding="size-250"
      borderRadius="regular"
      colorVersion={6}
    >
      <Flex width="100%" height="100%" justifyContent="space-between">
        <Flex width="40%" height="100%" direction="column" alignItems="center" justifyContent="center" gap="size-100">
          <Avatar
            size={100}
            src={`https://api.dicebear.com/9.x/initials/svg?seed=${item.username}`}
            alt={"Avatar de : " + item.username}
          />

          <Heading>{item.username}</Heading>
        </Flex>

        <Flex width="55%" height="100%" direction="column" alignItems="end" justifyContent="center" gap="size-100">
          <Text>génération {item?.born_in_the}'s</Text>
          <Divider size="S" />
          <Text>{item?.city}</Text>

          <Divider size="S" />
          <Text>{item?.zodiac}</Text>
        </Flex>
      </Flex>
      <Well></Well>
      <Flex justifyContent="end" width="100%" direction="row" alignItems="center" gap="size-100">
        <ActionButton isQuiet>
          <Send />
        </ActionButton>{" "}
        <ToggleButton isQuiet variant="secondary">
          <Add />
        </ToggleButton>
      </Flex>{" "}
    </View>
  );
}

export default UserCard;
