import { supabase } from "../../../supabaseClient";

export const getUserInfo = async () => {
  const { data, error } = await supabase.auth.getUser();

  if (error) {
    throw new Error(error.message);
  }

  return data?.user;
};
