import useSWR from "swr";
import { fetchUnreadCounts } from "./fetchUnreadCounts";

export const useUnreadCounts = () => {
  const { data, error, isLoading } = useSWR("get_unread_counts", async () => {
    try {
      return await fetchUnreadCounts();
    } catch (err) {
      // Propager l'erreur à SWR
      throw new Error(err.message);
    }
  });

  return {
    data,
    error,
    isLoading,
  };
};
