import useSWR, { mutate } from "swr";
import { getUserFullInfo } from "./getUserFullInfo";

export const useUserFullInfo = (userId) => {
  const {
    data,
    error: fetchError,
    isLoading,
  } = useSWR(userId ? ["get_user_full_info", userId] : null, ([, userId]) => getUserFullInfo(userId));

  // Ajouter une méthode pour mettre à jour localement les données utilisateur
  const updateUserInfo = (updatedUserInfo) => {
    // Mettre à jour le cache SWR localement
    mutate(
      ["get_user_full_info", userId],
      (currentData) => {
        if (!currentData?.data) return currentData;
        return {
          ...currentData,
          data: currentData.data.map((user) =>
            user.id === updatedUserInfo.id ? { ...user, ...updatedUserInfo } : user,
          ),
        };
      },
      false,
    ); // `false` pour éviter de revalider immédiatement
  };

  return {
    userInfo: data?.data,
    error: data?.error || fetchError,
    isLoading,
    updateUserInfo,
  };
};
