import {
  Content,
  ContextualHelp,
  Well,
  Flex,
  DialogContainer,
  AlertDialog,
  Heading,
  Text,
  Link,
  InlineAlert,
} from "@adobe/react-spectrum";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import { useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { supabase } from "../supabaseClient";
import UserSession from "../contexts/UserSession";

export default function SignTerms() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    error: null,
    isLoading: false,
  });
  const { session, updateTerms } = useContext(UserSession);

  const onPrimaryAction = async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const { error } = await supabase.from("terms").insert([
        {
          accepted_at: new Date(),
          user_id: session?.user?.id,
        },
      ]);

      if (error) {
        setState({ error: error.message, isLoading: false });
      } else {
        console.log("Timestamp inséré avec succès !");
        setState({ error: null, isLoading: false });
        updateTerms();
      }
    } catch (err) {
      setState({ error: "Une erreur s'est produite. Veuillez réessayer.", isLoading: false });
    }
  };

  const alertCancel = () => navigate(`../${PUBLIC_ROUTES.contact}`);

  return (
    <DialogContainer type="fullscreenTakeover">
      <AlertDialog
        variant={state.error ? "error" : "confirmation"}
        autoFocusButton="primary"
        title="Conditions Générales"
        primaryActionLabel={state.isLoading ? "Chargement..." : state.error ? "Essayer à nouveau" : "Continuer"}
        onPrimaryAction={onPrimaryAction}
        cancelLabel="Nous contacter"
        onCancel={alertCancel}
      >
        {state.error && (
          <InlineAlert marginY="size-250" variant="notice" width="100%">
            <Heading> Vous avez déjà accepté les CGU et les CGV.</Heading>
            <Content>
              <Text lang="en-US">{state.error}</Text>
            </Content>
          </InlineAlert>
        )}
        <Well>
          {" "}
          <Flex direction="column">
            <Text>
              En continuant, je reconnais avoir lu, compris et accepté les éléments suivants :<TermsContextualHelp />
            </Text>
            <Link marginTop="size-100" variant="secondary" onPress={() => navigate(`../${PUBLIC_ROUTES.CGV_CGU}`)}>
              Les Conditions Générales de Vente (CGV)
            </Link>
            <Link marginTop="size-100" variant="secondary" onPress={() => navigate(`../${PUBLIC_ROUTES.CGV_CGU}`)}>
              Les Conditions Générales d&apos;Utilisation (CGU)
            </Link>
            <Link
              marginTop="size-100"
              variant="secondary"
              onPress={() => navigate(`../${PUBLIC_ROUTES.mentionsLegales}`)}
            >
              La Politique de Confidentialité conformément au RGPD
            </Link>
          </Flex>
        </Well>
      </AlertDialog>
    </DialogContainer>
  );
}

const TermsContextualHelp = () => (
  <ContextualHelp variant="info">
    <Heading>Ouverture de compte et Conformité aux Réglementations</Heading>
    <Content>
      <Well>
        La création de compte est possible sans recours à un système d&apos;identification tiers (Facebook, Google,
        Microsoft, Apple...), cependant, un email est nécessaire.
      </Well>
      <Well marginY="size-50">
        Pour finaliser la création d&apos;un compte, une confirmation de l&apos;email renseigné est requise.
      </Well>
      <Well marginY="size-50">
        La politique de confidentialité et de respect de la vie privée est accessible depuis toutes les pages du site.
      </Well>
      <Well marginY="size-50">La connexion à tous les services proposés est possible avec les mêmes identifiants.</Well>
      <Well marginY="size-50">
        Les comptes ou abonnements ouverts en ligne peuvent être fermés de manière aussi simple en utilisant le même
        moyen. Il est également possible de se déconnecter des espaces privés.
      </Well>
      <Well marginY="size-50">
        Il est impératif d&apos;être légalement majeur et en mesure de prendre des décisions en toute responsabilité,
        conformément à la loi.
      </Well>
      <Well marginY="size-50">
        En résumé : Ne pas utiliser tramway.life pour du contenu illégal ou pour adultes. Aidez-nous à Assurer la
        sécurité du Tramway en signalant tout contenu illégal ou inapproprié rencontré via la page contact. Utiliser
        uniquement tramway.life de manière légale, authentique et positive.
      </Well>
    </Content>
  </ContextualHelp>
);
