import { supabase } from "../../../supabaseClient";

// Fonction pour vérifier le challenge MFA
export const challengeAndVerifyMFA = async (factorId, code) => {
  const { data, error } = await supabase.auth.mfa.challengeAndVerify({
    factorId,
    code,
  });

  // Retourne un objet contenant soit `data`, soit `error`
  return {
    data: data || null,
    error: error || null,
  };
};
