import PropTypes from "prop-types";
import { Navigate, Outlet } from "react-router-dom";
import { PUBLIC_ROUTES } from "../constants/publicroutes";
import SignTerms from "./SignTerms";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useEffect, memo, useState } from "react";
import Loading from "../components/Loading";

const ProtectedRoute = memo(({ isAuthenticated, sessionTerms }) => {
  const [storedSessionTerms, setStoredSessionTerms] = useLocalStorage("sessionTerms", null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);

      const timer = new Promise((resolve) => setTimeout(resolve, 1000));

      if (!storedSessionTerms && sessionTerms) {
        setStoredSessionTerms(sessionTerms);
      }

      await timer;
      setIsLoading(false);
    };

    initialize();
  }, [storedSessionTerms, sessionTerms, setStoredSessionTerms]);

  const hasAcceptedTerms = !!(sessionTerms || storedSessionTerms);
  const isUserAuthenticated = isAuthenticated === "authenticated";

  // Affichage du chargement si nécessaire
  if (isLoading) {
    return <Loading />;
  }

  // Redirection si les termes n'ont pas été acceptés
  if (!hasAcceptedTerms) {
    return <SignTerms />;
  }

  // Redirection si l'utilisateur n'est pas authentifié
  if (!isUserAuthenticated) {
    return <Navigate to={PUBLIC_ROUTES.connexion} replace />;
  }

  // Affichage de l'élément enfant si toutes les conditions sont remplies
  return <Outlet />;
});

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.string.isRequired,
  sessionTerms: PropTypes.string,
};

export default ProtectedRoute;
