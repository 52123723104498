import useSWRMutation from "swr/mutation";
import useSWR, { mutate } from "swr";
import { createSignedUrls } from "./createSignedUrls";

export const useCreateSignedUrls = () => {
  const avatarsKey = "avatars-urls-cache";
  const galleryKey = "gallery-urls-cache";

  // Utilisation de useSWR pour récupérer les caches
  const { data: avatarsData = {}, error: avatarsError } = useSWR(avatarsKey);
  const { data: galleryData = {}, error: galleryError } = useSWR(galleryKey);

  const { trigger, error, isMutating } = useSWRMutation([avatarsKey, galleryKey], async (_, { arg }) => {
    if (!arg || !arg.bucket || !arg.paths) {
      throw new Error("Invalid arguments for createSignedUrl");
    }

    const { bucket, paths } = arg;

    // Déterminer le type de contenu (avatars ou galerie) basé sur le bucket
    const isAvatarBucket = bucket === "avatars";
    const isGalleryBucket = bucket === "gallery";

    if (!isAvatarBucket && !isGalleryBucket) {
      throw new Error("Bucket must be either 'avatars' or 'gallery'");
    }

    // Filtrer les chemins manquants dans les caches correspondants
    const missingPaths = paths.filter((path) => {
      // Vérifier si le chemin correspond au type de contenu
      const isAvatarPath = isAvatarBucket && !avatarsData[path];
      const isGalleryPath = isGalleryBucket && !galleryData[path];
      return isAvatarPath || isGalleryPath;
    });

    // Créer un objet pour les nouvelles entrées de cache
    let newCacheEntries = {};

    // Effectuer l'appel API pour les chemins manquants
    if (missingPaths.length > 0) {
      const apiResults = await createSignedUrls(bucket, missingPaths);
      apiResults.forEach(({ path, signedUrl }) => {
        newCacheEntries[path] = { signedUrl, path };
      });
    }

    // Mise à jour des caches respectifs
    if (isAvatarBucket) {
      const updatedAvatarsCache = {
        ...avatarsData,
        ...newCacheEntries,
      };
      mutate(avatarsKey, updatedAvatarsCache, false);
    }

    if (isGalleryBucket) {
      const updatedGalleryCache = {
        ...galleryData,
        ...newCacheEntries,
      };
      mutate(galleryKey, updatedGalleryCache, false);
    }

    return {
      updatedAvatarsCache: isAvatarBucket ? { ...avatarsData, ...newCacheEntries } : avatarsData,
      updatedGalleryCache: isGalleryBucket ? { ...galleryData, ...newCacheEntries } : galleryData,
    };
  });

  return {
    createSignedUrls: trigger,
    error,
    isLoading: isMutating,
    avatarsError,
    galleryError,
  };
};
