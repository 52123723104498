import { supabase } from "../../../supabaseClient";

export const fetchProfiles = async ({
  userId,
  isLiked,
  isVisited,
  isFlahed,
  page = 1,
  limit = 10,
  sortBy = "updated_at",
  order = "desc",
  signal,
} = {}) => {
  const start = (page - 1) * limit;
  const end = start + limit - 1;

  // Initialisation de la requête de base
  let query = supabase.from("secure_profiles").select("*", { count: "exact" });

  // Si userId existe, on applique la condition eq
  if (userId) {
    query = query.eq("id", userId);
  }

  // Si isLiked est vrai, on applique la condition pour filtrer par isLiked
  if (isLiked) {
    query = query.eq("liked", true);
  }
  // Si isVisited est vrai, on applique la condition pour filtrer par isVisited
  if (isVisited) {
    query = query.eq("visited", true);
  }
  // Si isVisited est vrai, on applique la condition pour filtrer par isVisited
  if (isFlahed) {
    query = query.eq("flahed", true);
  }

  // Exécution de la requête avec les autres filtres et options
  const { data, error, count } = await query
    .order(sortBy, { ascending: order === "asc" })
    .range(start, end)
    .abortSignal(signal);

  if (error) {
    throw new Error(`Erreur lors de la récupération des profils: ${error.message}`);
  }

  return {
    items: data,
    total: count,
    hasNextPage: end < count - 1,
    currentPage: page,
  };
};
