import { IllustratedMessage, Heading, View, Flex, Content } from "@adobe/react-spectrum";

import Unauthorized from "@spectrum-icons/illustrations/Unauthorized";

function PrivatePage() {
  return (
    <>
      <View minHeight="85vh">
        <Flex direction="column" gap="size-100" alignItems="center" justifyContent="center" marginTop="size-400">
          <IllustratedMessage>
            <Unauthorized />
            <Heading>Tramway</Heading>
            <Content>Accès à l`&apos;`espace personnel </Content>
          </IllustratedMessage>
        </Flex>
      </View>
    </>
  );
}

export default PrivatePage;
