import { supabase } from "../../../supabaseClient";

export const uploadAvatar = async (bucket, fileName, file) => {
  try {
    const { data, error } = await supabase.storage.from(bucket).upload(fileName, file, {
      contentType: file.type,
      cacheControl: "3600", // Contrôle du cache
      upsert: true, // Remplacer le fichier s'il existe
    });

    if (error) {
      throw new Error(`Error uploading file: ${error.message}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw new Error(`Failed to upload file: ${err.message}`);
  }
};
