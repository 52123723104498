import PrivateLayout from "../../components/Layout/PrivateLayout";
import ConversationList from "../../components/Chat/ConversationList";

export default function Messages() {
  return (
    <PrivateLayout>
      <ConversationList />
    </PrivateLayout>
  );
}
