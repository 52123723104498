const COLORTHEMES = Object.freeze({
  light: "light",
  dark: "dark",
});

const SCALE = Object.freeze({
  medium: "medium",
  large: "large",
});

const TYPO = Object.freeze({
  nunito: "nunito",
  OpenDyslexic: "OpenDyslexic",
});

const BOTTOM_NAV = Object.freeze({
  membres: "Wayers",
  cercles: "Cercles",
  likedYou: "Likes",
  message: "Courrier",
  profile: "Profil",
});

export { COLORTHEMES, SCALE, BOTTOM_NAV, TYPO };
