import { Image, Text, Link, Flex, Heading, View, Button } from "@adobe/react-spectrum";
import useUnsplashPhoto from "../Hooks/useUnsplashPhoto.jsx";
import Heart from "@spectrum-icons/workflow/Heart";
import { PUBLIC_ROUTES } from "../../../constants/publicroutes";
import { useNavigate } from "react-router-dom";
function FeatureOne() {
  const navigate = useNavigate();

  const { photoUrl, portfolioUrl, name, altDescription } = useUnsplashPhoto("tmZcGf9ixPE", "regular");

  return (
    <View>
      <Flex marginY="size-500" direction="column" gap="size-200" alignItems="start">
        <Heading level={2} UNSAFE_style={{ textDecoration: "underline wavy", fontSize: "2rem" }} marginY="size-0">
          Bouton d&apos;Or
        </Heading>
        <Heading marginY="size-10" level={3}>
          La beauté se rencontre dans la simplicité
        </Heading>

        <Image
          maxHeight={{ base: "size-4600", S: "size-2000", M: "size-2000", L: "size-5000" }}
          width={{ base: "100%", S: "100%", M: "100%", L: "100%" }}
          alt={altDescription}
          src={photoUrl}
          objectFit="cover"
        />

        <Text>
          Prêt à tomber amoureux ou à créer des liens authentiques ? Tramway vous offre un espace très simple, efficace,
          pour aller à l&apos;essentiel. Le point de départ de l&apos;aventure à deux. Ici, les rencontres sont en phase
          avec vos valeurs de vie responsable et durable, au cœur de la nature, de l&apos;Outdoor et des modes de vie
          alternatifs. Trouve ton Coeur d&apos;artichaut.{" "}
          <Link
            target="blank"
            rel="noopener noreferrer"
            variant="secondary"
            // isQuiet
            href={portfolioUrl}
          >
            {"Crédit photo : " + name}
          </Link>
        </Text>

        <Button
          UNSAFE_style={{
            transform: "rotate(-2deg)",
            borderRadius: "20% 25% 20% 24%",
          }}
          alignSelf="end"
          variant="accent"
          style="fill"
          aria-label="Entamez votre périple ici - Rejoindre Tramway"
          onPress={() => navigate("./" + PUBLIC_ROUTES.creerCompte)}
        >
          <Heart />
          <Text>Entamez votre périple ici</Text>
        </Button>
      </Flex>
    </View>
  );
}

export default FeatureOne;
