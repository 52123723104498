import useSWRMutation from "swr/mutation";
import { downloadFile } from "./downloadFile";

export const useDownloadFile = () => {
  const { trigger, data, error, isMutating } = useSWRMutation("download_file", async (_, { arg }) => {
    const { bucket, filePath } = arg;
    return await downloadFile(bucket, filePath);
  });

  return {
    download: trigger, // Fonction pour déclencher le téléchargement
    data, // Fichier téléchargé
    error, // Erreur éventuelle
    isMutating, // État de mutation (en cours de téléchargement)
  };
};
