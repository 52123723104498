import { supabase } from "../../../supabaseClient";

export const downloadFile = async (bucket, filePath) => {
  const { data, error } = await supabase.storage.from(bucket).download(filePath);

  if (error) {
    throw new Error(`Erreur lors du téléchargement du fichier : ${error.message}`);
  }

  return data;
};
