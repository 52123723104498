import { supabase } from "../../../supabaseClient";

export const toggleLike = async (likedId) => {
  if (!likedId) {
    throw new Error("L'identifiant de l'élément à liker est requis.");
  }

  try {
    const { error } = await supabase.rpc("like_dislike", {
      liked_uuid: likedId,
    });

    if (error) {
      console.error("Erreur lors de l'appel RPC:", error.message);
      throw new Error(`Impossible de modifier l'état du like : ${error.message}`);
    }
  } catch (err) {
    console.error("Erreur inattendue:", err.message);
    throw new Error(`Erreur inattendue : ${err.message}`);
  }
};
