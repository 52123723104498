import useSWRMutation from "swr/mutation";
import { toggleLike } from "./toggleLike";

export const useToggleLike = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    "toggleLike",
    (_, { arg }) => toggleLike(arg), // La fonction prend `arg` comme argument.
  );

  return {
    toggleLike: trigger,
    isLoading: isMutating,
    error,
  };
};
