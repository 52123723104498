import { Flex } from "@adobe/react-spectrum";

import UserCard from "./UserCard";

function UserListView({ secureList }) {
  const { items } = secureList;

  return (
    <Flex
      width="100%"
      marginTop="size-250"
      direction="row"
      gap="size-100"
      wrap
      justifyContent="space-between"
      alignItems="center"
    >
      {items?.map((item) => (
        <UserCard key={item?.id} item={item} />
      ))}
    </Flex>
  );
}

export default UserListView;
