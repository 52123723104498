import { supabase } from "../../../supabaseClient";

// Fonction pour récupérer les préférences utilisateur via RPC
export const fetchUserPreferences = async (userId) => {
  const { data, error } = await supabase.rpc("get_user_preferences", { p_user_id: userId });

  if (error) {
    throw new Error(error.message);
  }

  return data;
};
