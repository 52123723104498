import { supabase } from "../../../supabaseClient";

export const createSignedUrls = async (bucket, paths) => {
  const { data, error } = await supabase.storage.from(bucket).createSignedUrls(paths, 14400);

  if (error) {
    throw new Error(`Error creating signed URL: ${error.message}`);
  }

  return data;
};
