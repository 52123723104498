import PrivateLayout from "../../components/Layout/PrivateLayout";
import UserListView from "../../components/MemberData/UserListView";
import ProfilTabs from "../../components/MemberData/ProfilTabs";
import { ProgressBar } from "@adobe/react-spectrum";

import { useAsyncProfiles } from "../../services/rpc_supabase/bucket";

export default function Profil() {
  const isOwnProfile = true;
  const isLiked = false;
  const isVisited = false;
  const isFlahed = false;

  const { secureList } = useAsyncProfiles(isOwnProfile, isLiked, isVisited, isFlahed);

  return (
    <PrivateLayout>
      {secureList.loadingState === "loading" ? (
        <ProgressBar
          height="size-700"
          size="L"
          labelPosition="side"
          minWidth="100%"
          label="Chargement"
          isIndeterminate
        />
      ) : (
        <UserListView isOwnProfile={true} secureList={secureList} />
      )}
      <ProfilTabs secureList={secureList} />
    </PrivateLayout>
  );
}
