import { Image } from "@adobe/react-spectrum";
// import useUnsplashPhoto from "../Hooks/useUnsplashPhoto";

function VisualExamples() {
  // const { photoUrl, portfolioUrl, name, altDescription } = useUnsplashPhoto("XfGHiAmDkJo", "small");

  let photoUrl = "alternative_attitude.png";
  let altDescription =
    "Un couple amoureux allongé dans l'herbe, devant leur tente, observe la montagne à l'aube. La scène romantique est baignée de la lumière douce du soleil levant, capturant un moment intime en pleine nature.";

  return (
    <Image
      loading="lazy"
      width={{ base: "100%", S: "100%", M: "80%", L: "100%" }}
      alt={altDescription ?? ""}
      // src={photoUrl}
      src={photoUrl ?? "alternative_attitude.png"}
      objectFit="cover"
    />
  );
}

export default VisualExamples;
