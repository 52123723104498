import { useState } from "react";
import {
  ActionMenu,
  Heading,
  Content,
  IllustratedMessage,
  ActionBar,
  ActionBarContainer,
  ListView,
  Item,
  Text,
  Image,
  StatusLight,
  ActionGroup,
  Divider,
  View,
  Flex,
  LabeledValue,
} from "@adobe/react-spectrum";

import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";

import Chat from "@spectrum-icons/workflow/Chat";
import Delete from "@spectrum-icons/workflow/Delete";
import Heart from "@spectrum-icons/workflow/Heart";
import More from "@spectrum-icons/workflow/Delete";
import PushNotification from "@spectrum-icons/workflow/PushNotification";
import UserExclude from "@spectrum-icons/workflow/UserExclude";

import { useMediaQuery } from "@uidotdev/usehooks";

function renderEmptyState() {
  return (
    <IllustratedMessage maxWidth="100%" margin="size-400">
      <Chat size="L" aria-label="Messagerie du Tramway" />
      <Heading>Il n&apos;y a pas encore de courrier.</Heading>
      <Content>
        Tramway • courant alternatif <span title="Emoji : Marque déposée"></span>®
      </Content>
    </IllustratedMessage>
  );
}

function ConversationList() {
  let [selectedKeys, setSelectedKeys] = useState(new Set());
  let [selectionMode, setSelectionMode] = useState("single");
  let [isFavoris, setIsFavorit] = useState(false);
  let [isUnRead, setIsUnRead] = useState(false);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const chatList = [
    { key: "1", name: "user1", lastMessage: "Salut!" },
    { key: "2", name: "user2", lastMessage: "Hello" },
    { key: "3", name: "user3", lastMessage: "Hey ;-)" },
    { key: "4", name: "user4", lastMessage: "Coucou!" },
    { key: "5", name: "user5", lastMessage: "Bonjour..." },
  ];

  const actionMenuItems = [
    { key: "delete", name: "Supprimer", icon: <Delete /> },
    { key: "block", name: "Bloquer", icon: <UserExclude /> },
    { key: "addToFavorites", name: "Ajouter aux favoris", icon: <Heart /> },
    { key: "markAsUnread", name: "Marqué comme non lu", icon: <PushNotification /> },
  ];

  const barItems = [
    { key: "delete", label: "Supprimer", icon: <Delete /> },
    { key: "unread", label: "Marquer comme non lu", icon: <PushNotification /> },
  ];

  const handleActionMenu = (actionKey, itemKey) => {
    switch (actionKey) {
      case "delete":
        console.log(itemKey);

        setSelectedKeys(new Set(itemKey));
        console.log("Action sélectionnée : Supprimer");
        break;
      case "block":
        setSelectedKeys(new Set(itemKey));
        console.log("Action sélectionnée : Bloquer");
        break;
      case "addToFavorites":
        setSelectedKeys(new Set(itemKey));
        console.log("Action sélectionnée : Ajouter aux favoris");
        break;
      case "markAsUnread":
        setSelectedKeys(new Set(itemKey));
        console.log("Action sélectionnée : Marqué comme non lu");
        break;
      default:
        setSelectedKeys(new Set());
        console.warn(`Action inconnue : ${actionKey}`);
    }
  };

  const handleActionBar = (key) => {
    switch (key) {
      case "delete":
        console.log("Supprimer l'élément sélectionné");
        break;
      case "unread":
        console.log("Marquer comme non lu");
        break;
      default:
        console.log("Action inconnue : ", key);
    }
  };

  console.log(selectedKeys);

  const handleGlobalAction = (actionKey) => {
    switch (actionKey) {
      case "selectionmode":
        setSelectionMode(selectionMode === "multiple" ? "single" : "multiple");
        setSelectedKeys(new Set());
        break;
      case "favoris":
        setSelectionMode("single");
        setSelectedKeys(new Set());
        setIsFavorit(!isFavoris);
        break;
      case "unread":
        setSelectionMode("single");
        setSelectedKeys(new Set());
        setIsUnRead(!isUnRead);
        break;
      default:
        console.warn(`Action inconnue : ${actionKey}`);
    }
  };

  const formatRelativeDate = (dateString) => {
    return formatDistanceToNow(new Date(dateString), { addSuffix: isSmallDevice ? false : true, locale: fr });
  };

  return (
    <>
      <ActionGroup
        isEmphasized
        isQuiet
        density="compact"
        aria-label="Actions sur la liste des conversations"
        summaryIcon={<More />}
        maxWidth={1000}
        overflowMode="collapse"
        onAction={(key) => handleGlobalAction(key)}
        selectionMode="single"
        defaultSelectedKeys={[]}
      >
        <Item aria-label={selectionMode === "single" ? "Selectionner" : "Annuler"} key="selectionmode">
          {" "}
          {selectionMode === "single" ? "Selectionner" : "Annuler"}
        </Item>
        <Item aria-label="Favoris" key="favoris">
          Favoris
        </Item>
        <Item aria-label="Non lu" key="unread">
          Non lu
        </Item>
      </ActionGroup>
      <Divider size="S" marginY="size-250" />
      <ActionBarContainer height="3000" width={{ base: "100%", S: "100%", M: "100%", L: "45%" }}>
        <ListView
          isQuiet
          isEmphasized={true}
          width="100%"
          aria-label="List des conversations"
          selectionMode={selectionMode}
          selectedKeys={selectedKeys}
          onSelectionChange={setSelectedKeys}
          density="spacious"
          overflowMode="truncate"
          items={chatList}
          selectionStyle={selectionMode === "single" ? "highlight" : "checkbox"}
          loadingState={chatList?.loadingState}
          onLoadMore={chatList?.loadMore}
          renderEmptyState={renderEmptyState}
        >
          {(item) => (
            <Item hasChildItems key={item.key} aria-label={item.name} textValue={item.name}>
              <Image src="https://random.dog/1a0535a6-ca89-4059-9b3a-04a554c0587b.jpg" alt="Shiba Inu with glasses" />
              <Text>
                <Flex justifyContent="space-between">
                  <Text>{item.name} </Text>{" "}
                  <LabeledValue
                    UNSAFE_style={{ fontSize: "12px" }}
                    labelPosition="side"
                    aria-label="date"
                    value={formatRelativeDate("2024-12-31 09:41:37.18704")}
                  />
                  <StatusLight role="status" variant="info" aria-label="Message non lu" />{" "}
                </Flex>{" "}
              </Text>

              <Text slot="description">{item.lastMessage}</Text>

              <ActionMenu onAction={(key) => handleActionMenu(key, item.key)} isQuiet autoFocus items={actionMenuItems}>
                {(item) => (
                  <Item textValue={item.name} key={item.key}>
                    {item.icon}
                    <Text>{item.name}</Text>
                  </Item>
                )}
              </ActionMenu>
            </Item>
          )}
        </ListView>
        {selectionMode === "multiple" && (
          <ActionBar
            position="sticky"
            items={barItems}
            isEmphasized
            selectedItemCount={selectedKeys === "all" ? "all" : selectedKeys.size}
            onAction={(key) => handleActionBar(key)}
            onClearSelection={() => {
              setSelectedKeys(new Set());
              setSelectionMode("single");
            }}
          >
            {(item) => (
              <Item key={item.key}>
                {item.icon}
                <Text>{item.label}</Text>
              </Item>
            )}
          </ActionBar>
        )}
      </ActionBarContainer>
    </>
  );
}

export default ConversationList;
