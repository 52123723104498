import { supabase } from "../../../supabaseClient";

// Fonction pour récupérer les facteurs MFA
export const listMFAFactors = async () => {
  const { data, error } = await supabase.auth.mfa.listFactors();

  if (error) {
    throw new Error(error.message);
  }

  return {
    totp: data?.totp || [],
    phone: data?.phone || [],
    all: data?.all || [],
  };
};
