import useSWRMutation from "swr/mutation";
import { challengeAndVerifyMFA } from "./challengeAndVerifyMFA";

// Hook pour gérer la vérification MFA
export const useMFAChallengeAndVerify = () => {
  const { trigger, data, error, isMutating } = useSWRMutation("mfa_challenge_verify", async (_, { arg }) => {
    const { factorId, code } = arg;
    const { data, error } = await challengeAndVerifyMFA(factorId, code);
    if (error) {
      throw new Error(error);
    }
    return data;
  });

  return {
    verifyMFA: trigger,
    data,
    error,
    isLoading: isMutating,
  };
};
