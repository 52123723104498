import { supabase } from "../../../supabaseClient";

// Fonction pour marquer tous les flashes comme lus
export const markAllFlashesAsRead = async () => {
  try {
    const { error } = await supabase.rpc("mark_all_flashes_as_read");

    if (error) {
      throw new Error(`Error marking flashes as read: ${error.message}`);
    }

    return true; // Si tout se passe bien, renvoyer un succès
  } catch (err) {
    console.error(err);
    throw new Error(`Failed to mark flashes as read: ${err.message}`);
  }
};
