import useSWR from "swr";
import { fetchUserPreferences } from "./fetchUserPreferences";

export const useUserPreferences = (userId) => {
  const { data, error, isLoading } = useSWR(userId ? ["get_user_preferences", userId] : null, () =>
    fetchUserPreferences(userId),
  );

  return {
    data,
    error,
    isLoading,
  };
};
