import "../global.js";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import FormMFA from "./components/FormMFA.jsx";
import GlobalProvider from "./providers/GlobalProvider.jsx";
import "./style.css";
import Loading from "./components/Loading.jsx";
import { useAuthenticatorAssuranceLevel } from "./services/rpc_supabase/auth/useAuthenticatorAssuranceLevel.jsx";
import Notifications from "./components/Notifications.jsx";

// In Google Analytics 4, IP anonymization is not necessary since IP addresses are not logged or stored.
import ReactGA from "react-ga4";
ReactGA.initialize([
  {
    trackingId: "G-KBY3PBPVNE",
    gaOptions: { anonymize_ip: true },
    gtagOptions: { anonymize_ip: true },
  },
]);

export default function AppWithMFA() {
  const [readyToShow, setReadyToShow] = useState(false);
  const [showMFAScreen, setShowMFAScreen] = useState(false);
  const { currentLevel, nextLevel, error, isLoading } = useAuthenticatorAssuranceLevel();

  useEffect(() => {
    (async () => {
      try {
        if (error) {
          console.error(error);
          return;
        }

        if (isLoading) {
          return;
        }

        if (nextLevel === "aal2" && nextLevel !== currentLevel) {
          setShowMFAScreen(true);
        }
      } finally {
        setReadyToShow(true);
      }
    })();
  }, [currentLevel, nextLevel, error, isLoading]);

  if (isLoading) {
    return <Loading />;
  }

  if (readyToShow) {
    if (showMFAScreen) {
      return <FormMFA />;
    }

    return <App />;
  }

  return null;
}

const rootElement = document.getElementById("root");
const reactNode = (
  <React.StrictMode>
    <BrowserRouter>
      <GlobalProvider>
        <HelmetProvider>
          <Notifications />
          <AppWithMFA />
        </HelmetProvider>
      </GlobalProvider>
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.createRoot(rootElement).render(reactNode);

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
