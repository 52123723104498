import { supabase } from "../../../supabaseClient";

export const removeFiles = async (bucket, arrayOfFilePaths) => {
  const { data, error } = await supabase.storage.from(bucket).remove(arrayOfFilePaths);

  if (error) {
    throw new Error(`Erreur lors de la suppression des fichiers : ${error.message}`);
  }

  return data;
};
