import { useAsyncList } from "@adobe/react-spectrum";
import { fetchProfiles } from "./fetchProfiles";
import { getUserInfo } from "../auth/getUserInfo";
import { useCreateSignedUrls } from "../bucket";

export const useAsyncProfiles = (isOwnProfile, isLiked, isVisited, isFlahed) => {
  const { createSignedUrls } = useCreateSignedUrls();

  const secureList = useAsyncList({
    async load({ signal, cursor }) {
      let userId = null;

      try {
        // Récupération de l'utilisateur connecté si nécessaire
        if (isOwnProfile) {
          const user = await getUserInfo();
          if (user) {
            userId = user?.id;
          }
        }

        // Récupération des profils (données brutes)
        const profiles = await fetchProfiles({
          page: cursor || 1,
          signal,
          isOwnProfile,
          userId: isOwnProfile ? userId : null,
          isLiked: isLiked || null,
          isVisited: isVisited || null,
          isFlahed: isFlahed || null,
        });

        const items = profiles.items;

        // Collecter toutes les URLs d'avatars et de galeries pour les signer
        const avatarPaths = [];
        const galleryPaths = [];

        items.forEach((profile) => {
          if (profile.avatar_url) {
            avatarPaths.push(profile.avatar_url);
          }
          if (Array.isArray(profile.gallery_urls)) {
            galleryPaths.push(...profile.gallery_urls);
          }
        });

        // Signer les URLs en arrière-plan sans bloquer le retour des données brutes
        const signUrlsPromises = [];

        if (avatarPaths.length > 0) {
          signUrlsPromises.push(createSignedUrls({ bucket: "avatars", paths: avatarPaths }));
        }
        if (galleryPaths.length > 0) {
          signUrlsPromises.push(createSignedUrls({ bucket: "gallery", paths: galleryPaths }));
        }

        // Attendre que toutes les promesses de signature soient résolues
        if (signUrlsPromises.length > 0) {
          await Promise.all(signUrlsPromises);
        }

        // Retourner les données brutes après avoir signé les URLs
        return {
          items,
          cursor: profiles.hasNextPage ? profiles.currentPage + 1 : null,
        };
      } catch (error) {
        console.error("Erreur lors de la récupération des profils:", error);
        return {
          items: [],
          cursor: null,
        };
      }
    },
  });

  return { secureList };
};
