import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { useAuthenticatorAssuranceLevel } from "../services/rpc_supabase/auth/useAuthenticatorAssuranceLevel";
import { useTermsAcceptance } from "../services/rpc_supabase/terms/useTermsAcceptance.jsx";

export const useUserSession = () => {
  const [sessionData, setSessionData] = useState({
    session: null,
    sessionEmail: "",
    sessionTerms: null,
    sessionRole: "",
    sessionEvent: "",
    sessionLastSignIn: "",
    sessionConfirmed: "",
    sessionExpires: null,
  });

  // Hook pour récupérer le niveau MFA
  const { currentLevel, nextLevel, error: mfaError, isLoading: isMFALoading } = useAuthenticatorAssuranceLevel();

  // Mettre à jour les données MFA dans l'état
  useEffect(() => {
    setSessionData((prevData) => ({
      ...prevData,
      currentLevelMFA: currentLevel,
      nextLevelMFA: nextLevel,
    }));
  }, [currentLevel, nextLevel]);

  // Récupération des données d'acceptation des termes
  const { acceptedAt } = useTermsAcceptance(sessionData.session?.user?.id) || {};

  useEffect(() => {
    setSessionData((prevData) => ({
      ...prevData,
      sessionTerms: acceptedAt || null,
    }));
  }, [acceptedAt]);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const {
          data: { session },
          error,
        } = await supabase.auth.getSession();

        if (error) {
          console.error("Erreur lors de la récupération de la session :", error);
          return;
        }

        if (session) {
          setSessionData((prevData) => ({
            ...prevData,
            session,
            sessionRole: session?.user?.role || "",
            sessionEmail: session?.user?.email || "",
            sessionLastSignIn: session?.user?.last_sign_in_at || "",
            sessionConfirmed: session?.user?.confirmed_at || "",
            sessionExpires: session?.expires_in || null,
          }));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données de session :", error);
      }
    };

    const handleAuthStateChange = (_event, session) => {
      setSessionData((prevData) => ({
        ...prevData,
        session,
        sessionRole: session?.user?.role || "",
        sessionEmail: session?.user?.email || "",
        sessionLastSignIn: session?.user?.last_sign_in_at || "",
        sessionConfirmed: session?.user?.confirmed_at || "",
        sessionExpires: session?.expires_in || null,
        sessionEvent: _event,
      }));
    };

    // Récupérer les données initiales de session
    fetchSessionData();

    // Abonner aux changements d'état d'authentification
    const { data: authListener } = supabase.auth.onAuthStateChange(handleAuthStateChange);

    // Nettoyage lors du démontage du composant
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return {
    ...sessionData,
    isMFALoading,
    mfaError,
    setNextLevelMFA: (nextLevel) => setSessionData((prevData) => ({ ...prevData, nextLevelMFA: nextLevel })),
    setCurrentLevelMFA: (currentLevel) =>
      setSessionData((prevData) => ({ ...prevData, currentLevelMFA: currentLevel })),
  };
};
