import useSWR from "swr";
import { markAllFlashesAsRead } from "./markAllFlashesAsRead";

// Hook pour marquer tous les flashes comme lus
export const useMarkAllFlashesAsRead = () => {
  const { mutate, isValidating, error } = useSWR(
    "mark_all_flashes_as_read",
    async () => {
      try {
        return await markAllFlashesAsRead();
      } catch (err) {
        throw new Error(err.message);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );

  return {
    markAllFlashesAsRead: mutate,
    isLoading: isValidating,
    error,
  };
};
