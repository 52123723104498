import { useNavigate } from "react-router-dom";

import Login from "@spectrum-icons/workflow/Login";
import { TooltipTrigger, Button, Tooltip } from "@adobe/react-spectrum";

import { PUBLIC_ROUTES } from "../constants/publicroutes";

function LoginBtn() {
  const nav = useNavigate();
  return (
    <TooltipTrigger>
      <Button
        UNSAFE_style={{
          transform: "rotate(-2deg)",
          borderRadius: "20% 25% 20% 24%",
        }}
        variant="accent"
        style="fill"
        aria-label="Connexion"
        onPress={() => nav("./" + PUBLIC_ROUTES.connexion)}
      >
        <Login aria-label="notifications" />
      </Button>
      <Tooltip>Connexion</Tooltip>
    </TooltipTrigger>
  );
}

export default LoginBtn;
