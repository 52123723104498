import { useState, lazy, Suspense, memo } from "react";

import Loading from "../../components/Loading";
import HomeTabs from "../../components/HomeTabs";
import PrivateLayout from "../../components/Layout/PrivateLayout";
import FullScreenMapBtn from "../../components/GeoCode/FullScreenMapBtn";

const SetlazySearchMembers = memo(lazy(() => import("../../components/MemberData/SearchMembersDialog")));

import Properties from "@spectrum-icons/workflow/Properties";
import ViewGrid from "@spectrum-icons/workflow/ViewGrid";
import ViewList from "@spectrum-icons/workflow/ViewList";
import PeopleGroup from "@spectrum-icons/workflow/PeopleGroup";
import Search from "@spectrum-icons/workflow/Search";

import { ActionButton, Flex, Text, DialogTrigger, ToggleButton, Tooltip, TooltipTrigger } from "@adobe/react-spectrum";

export default function Accueil() {
  let [isSearching, setIsSearching] = useState(false);
  let [isList, setIsList] = useState(true);

  return (
    <>
      <PrivateLayout
        width={{ base: "100%", L: "70%", M: "100%", S: "100%" }}
        borderWidth={{ base: "thin", L: "thin", M: "thin", S: "thin" }}
        borderColor="dark"
        borderRadius="medium"
        padding={{ base: "size-100", L: "size-250", M: "size-250", S: "size-250" }}
        height="size-5000"
      >
        <Flex justifyContent="space-between">
          <Flex alignItems="center" gap="size-100">
            {/* RECHERCHE INPUT */}
            <ToggleButton
              isSelected={isSearching}
              onChange={setIsSearching}
              aria-label={isSearching ? "Ma recherche" : "Les membres en ligne uniquement"}
            >
              {isSearching ? (
                <>
                  <Properties />
                  <Text>Ma recherche</Text>
                </>
              ) : (
                <>
                  <PeopleGroup />
                  <Text>Membres actifs</Text>
                </>
              )}
            </ToggleButton>

            {/* MAP */}
            <FullScreenMapBtn />

            <ToggleButton isSelected={isList} onChange={setIsList} aria-label={`Vue ${isList ? "liste" : "grille"}`}>
              {isList ? (
                <>
                  <ViewList />
                </>
              ) : (
                <>
                  <ViewGrid />
                </>
              )}
            </ToggleButton>
          </Flex>

          {/* RECHERCHE */}
          <DialogTrigger type="tray">
            <TooltipTrigger>
              <ActionButton
                isQuiet
                aria-label="Pramètres de recherche que nous dans ton compte personnel pour des recherches ultérieures."
                marginY="size-200"
              >
                <Search />
              </ActionButton>
              <Tooltip>Préférences de recherche</Tooltip>
            </TooltipTrigger>
            {() => (
              <Suspense fallback={<Loading />}>
                <SetlazySearchMembers />
              </Suspense>
            )}
          </DialogTrigger>
        </Flex>

        <HomeTabs isList={isList} isSearching={isSearching} />
      </PrivateLayout>
    </>
  );
}
