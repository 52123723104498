import useSWRMutation from "swr/mutation";
import { removeFiles } from "./removeFiles";

export const useRemoveFiles = () => {
  const { trigger, data, error, isMutating } = useSWRMutation("remove_files", async (_, { arg }) => {
    const { bucket, arrayOfFilePaths } = arg;

    return await removeFiles(bucket, arrayOfFilePaths);
  });

  return {
    remove: trigger,
    data,
    error,
    isMutating,
  };
};
