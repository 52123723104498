import useSWR from "swr";
import { getTermsAcceptanceDate } from "./getTermsAcceptanceDate";

// Hook personnalisé pour récupérer la date d'acceptation des termes
export const useTermsAcceptance = (userId) => {
  const { data, error, isLoading } = useSWR(
    userId ? `get_terms_acceptance_${userId}` : null, // La clé change en fonction de l'ID utilisateur
    () => getTermsAcceptanceDate(userId), // Appel de la fonction qui récupère la date d'acceptation
  );

  return {
    acceptedAt: data,
    error,
    isLoading,
  };
};
