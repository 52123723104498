import useSWR from "swr";
import { getAuthenticatorAssuranceLevel } from "./getAuthenticatorAssuranceLevel";

export const useAuthenticatorAssuranceLevel = () => {
  const { data, error, isLoading } = useSWR("get_authenticator_assurance_level", getAuthenticatorAssuranceLevel);

  return {
    currentLevel: data?.currentLevel,
    nextLevel: data?.nextLevel,
    currentAuthenticationMethods: data?.currentAuthenticationMethods,
    error,
    isLoading,
  };
};
