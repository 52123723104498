import { useState } from "react";
import {
  TextField,
  Flex,
  DialogContainer,
  Dialog,
  Heading,
  Divider,
  Content,
  Text,
  InlineAlert,
  ButtonGroup,
  Button,
  Form,
} from "@adobe/react-spectrum";

import LockOpen from "@spectrum-icons/workflow/LockOpen";
import Delete from "@spectrum-icons/workflow/Delete";

import useVibration from "../hooks/useVibration";
import { useMFAFactors } from "../services/rpc_supabase/auth/useMFAFactors";
import { useMFAChallengeAndVerify } from "../services/rpc_supabase/auth/useMFAChallengeAndVerify";

function FormMFA() {
  const { vibrate } = useVibration();
  const [verifyCode, setVerifyCode] = useState("");
  const [formError, setFormError] = useState(null); // Local error state

  const { factors, isLoading: isListing, error: errorListing } = useMFAFactors();
  const {
    verifyMFA,
    error: errorChallengeAndVerify,
    isLoading: isChallengingAndVerifying,
  } = useMFAChallengeAndVerify();

  // Combine external errors
  const combinedError = errorListing || errorChallengeAndVerify;

  const onSubmitClicked = async () => {
    setFormError(null);
    try {
      if (!verifyCode.trim()) {
        throw new Error("Le code MFA est requis.");
      }

      // Validate code length
      if (verifyCode.length !== 6 || !/^\d{6}$/.test(verifyCode)) {
        throw new Error("Le code MFA doit être un nombre à 6 chiffres.");
      }

      const factorId = factors?.all?.find((factor) => factor.friendly_name === "Tramway")?.id;

      if (!factorId) {
        throw new Error("Impossible de trouver le Factor ID pour 'Tramway'.");
      }

      await verifyMFA({ factorId, code: verifyCode });
    } catch (e) {
      setFormError(e.message);
    } finally {
      vibrate();
    }
  };

  return (
    <DialogContainer type="fullscreenTakeover">
      <Dialog>
        <Heading>Double authentification</Heading>
        <Divider />

        <Content>
          <Flex direction="column" gap="size-100" alignItems="center" justifyContent="center">
            <Form
              labelPosition="top"
              isRequired
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitClicked();
              }}
              maxWidth="size-3600"
            >
              {/* Display Errors */}
              {(formError || combinedError) && (
                <InlineAlert variant="negative" autoFocus={true} width="100%">
                  <Heading lang="en-US">Erreur</Heading>
                  <Content lang="en-US">{formError || combinedError?.message}</Content>
                </InlineAlert>
              )}

              {/* MFA Code Input */}
              <TextField
                label="Code MFA"
                name="mfa"
                type="password"
                isRequired
                autoFocus={true}
                marginY="size-200"
                width="100%"
                maxLength={6}
                aria-label="Code à 6 chiffres depuis votre application d'authentification"
                value={verifyCode}
                onChange={setVerifyCode}
              />

              {/* Action Buttons */}
              <ButtonGroup align="center" isDisabled={isChallengingAndVerifying || isListing}>
                <Button variant="secondary" type="reset" onPress={() => setVerifyCode("")}>
                  <Delete /> <Text>Effacer</Text>
                </Button>
                <Button variant="accent" type="submit">
                  <LockOpen />
                  <Text>Accéder</Text>
                </Button>
              </ButtonGroup>
            </Form>
          </Flex>
        </Content>
      </Dialog>
    </DialogContainer>
  );
}

export default FormMFA;
