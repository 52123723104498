import useSWR from "swr";
import { listMFAFactors } from "./listMFAFactors";

// Hook personnalisé pour obtenir les facteurs MFA
export const useMFAFactors = () => {
  const { data, error, isLoading, mutate } = useSWR(
    "listMFAFactors", // Clé de la requête
    listMFAFactors, // Fonction pour récupérer les données
  );

  return {
    factors: data, // Les facteurs récupérés (TOTP et téléphone)
    isLoading, // État de chargement
    error, // Erreur éventuelle
    refreshFactors: mutate, // Fonction pour rafraîchir les données
  };
};
