import { lazy, Suspense, memo, useContext, useRef, useMemo, useEffect } from "react";
import { Tabs, TabPanels, TabList, Text, ContextualHelp, Content, Heading, Item } from "@adobe/react-spectrum";

import Photo from "@spectrum-icons/workflow/ImageAdd";
import Details from "@spectrum-icons/workflow/UserEdit";
import Settings from "@spectrum-icons/workflow/Settings";
import ApproveReject from "@spectrum-icons/workflow/ApproveReject";

import Loading from "../Loading";
import UserSession from "../../contexts/UserSession";
import { useUserFullInfo } from "../../services/rpc_supabase/profiles/useUserFullInfo";

// Chargement paresseux des composants enfants
const LazyUserProfilDetails = memo(lazy(() => import("./ProfilDetails")));
const LazyProfilPictures = memo(lazy(() => import("../MemberMedia/ProfilPictures")));
const LazySettings = memo(lazy(() => import("../../components/Settings")));
const LazyEssentialInformation = memo(lazy(() => import("./EssentialInformation")));

export default function HomeTabs({ secureList }) {
  const { session, sessionRole, sessionEmail, setNextLevelMFA, nextLevelMFA, setCurrentLevelMFA } =
    useContext(UserSession);

  const userId = session?.user?.id;
  const isAuth = sessionRole === "authenticated";

  // Récupérer les données utilisateur directement
  const { userInfo, error, isLoading: isLoadingUserInfo, updateUserInfo } = useUserFullInfo(userId);

  // Calculer les clés désactivées
  const disabledKeys = useMemo(() => {
    if (!userInfo?.[0]?.minimum_requirement) {
      return ["2", "3"];
    }
    if (secureList?.loadingState === "loading") {
      return ["2"];
    }
    return [];
  }, [userInfo, secureList]);

  // Fonction de mise à jour progressive (sans état local)
  const updateUserDetails = (keyOrObject, value) => {
    if (!userInfo?.[0]) return;

    let updatedDetails;

    if (typeof keyOrObject === "object") {
      updatedDetails = { ...userInfo[0], ...keyOrObject };
    } else {
      updatedDetails = { ...userInfo[0], [keyOrObject]: value };
    }

    // Mettre à jour les informations utilisateur
    updateUserInfo(updatedDetails);
  };

  const LazyUserProfilDetailsRef = useRef();
  const LazyProfilPicturesRef = useRef();
  const LazySettingsRef = useRef();
  const LazyEssentialInformationRef = useRef();
  const staticUserInfo = JSON.stringify(userInfo?.[0]);

  useEffect(() => {
    if (userInfo?.[0]) {
      LazyUserProfilDetailsRef.current && LazyUserProfilDetailsRef.current.preload();
      LazyProfilPicturesRef.current && LazyProfilPicturesRef.current.preload();
      LazySettingsRef.current && LazySettingsRef.current.preload();
      LazyEssentialInformationRef.current && LazyEssentialInformationRef.current.preload();
    }
  }, [staticUserInfo]);

  return (
    <Tabs
      alignSelf="center"
      justifySelf="center"
      disallowEmptySelection
      defaultSelectedKey="1"
      density={error ? "compact" : "regular"}
      isEmphasized={!isLoadingUserInfo}
      orientation="horizontal"
      aria-label="Les membres du Tramway sont tous ici"
      disabledKeys={disabledKeys}
    >
      <TabList>
        <Item key="1" textValue="ID">
          <ApproveReject size="S" aria-label="ID" />
          <Text>Essentiel</Text>
        </Item>
        <Item key="2" textValue="Gallerie">
          <Photo size="S" aria-label="Gallerie" />
          <Text>Gallerie</Text>
        </Item>
        <Item key="3" textValue="Un peu plus sur le profil">
          <Details size="S" aria-label="Un peu plus sur le profil" />
          <Text>Un peu plus</Text>
        </Item>
        <Item key="4" textValue="Parametres">
          <Settings size="S" aria-label="Parametres" />
          <Text>Paramètres</Text>
        </Item>
      </TabList>

      <TabPanels marginTop="size-200" marginX="size-0">
        {/* Onglet Essentiel */}
        <Item key="1">
          Complète pour activer ton profil et commencer à rencontrer !
          <Suspense fallback={<Loading />}>
            <LazyEssentialInformation userDetails={userInfo?.[0]} onUpdate={updateUserDetails} userId={userId} />
          </Suspense>
        </Item>

        {/* Onglet Gallerie */}
        <Item key="2">
          Célèbre ton authenticité.
          <ContextualHelp variant="info">
            <Heading>Gallerie</Heading>
            <Content>
              <Text>Ajouter des photos depuis mon appareil au format JPG, PNG ou WEBP.</Text>
            </Content>
          </ContextualHelp>
          <Suspense fallback={<Loading />}>
            <LazyProfilPictures secureList={secureList} />
          </Suspense>
        </Item>

        {/* Onglet Profil */}
        <Item key="3">
          Ce qui te rends unique.
          <Suspense fallback={<Loading />}>
            <LazyUserProfilDetails userId={userId} />
          </Suspense>
        </Item>

        {/* Onglet Paramètres */}
        <Item key="4">
          Paramètres du compte
          <Suspense fallback={<Loading />}>
            <LazySettings
              setNextLevelMFA={setNextLevelMFA}
              setCurrentLevelMFA={setCurrentLevelMFA}
              nextLevelMFA={nextLevelMFA}
              userId={userId}
              isAuth={isAuth}
              sessionEmail={sessionEmail}
            />
          </Suspense>
        </Item>
      </TabPanels>
    </Tabs>
  );
}
