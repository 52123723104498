import useLazyLoadWithLayout from "../hooks/useLazyLoadWithLayout";

import Membres from "../pages/private/Membres";
import Circles from "../pages/private/Cercles";
import LikedYou from "../pages/private/LikedYou";
import Messages from "../pages/private/Messages";
import Profile from "../pages/private/Profil";
import PrivatePage from "../pages/private/PrivatePage";

function GetPrivateRoutes() {
  const membresLazy = useLazyLoadWithLayout(Membres);
  const circlesLazy = useLazyLoadWithLayout(Circles);
  const likedYouLazy = useLazyLoadWithLayout(LikedYou);
  const messagesLazy = useLazyLoadWithLayout(Messages);
  const profileLazy = useLazyLoadWithLayout(Profile);
  const privatePageLazy = useLazyLoadWithLayout(PrivatePage);

  return {
    membresLazy,
    likedYouLazy,
    circlesLazy,
    messagesLazy,
    profileLazy,
    privatePageLazy,
  };
}

export default GetPrivateRoutes;
