import { supabase } from "../../../supabaseClient";

// Fonction pour récupérer le niveau d'assurance MFA
export const getAuthenticatorAssuranceLevel = async () => {
  const { data, error } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
  if (error) {
    throw new Error(error.message);
  }
  return data;
};
