import { memo, useMemo, useContext } from "react";
import {
  Flex,
  Header,
  View,
  DialogTrigger,
  Button,
  Heading,
  Content,
  StatusLight,
  Divider,
  Dialog,
} from "@adobe/react-spectrum";
import TramwayAppIcon from "./TramwayIcon";
import NavItems from "./NavItems";
import LoginBtn from "./LoginBtn";
import UserSession from "../contexts/UserSession";
import PushNotification from "@spectrum-icons/workflow/PushNotification";
import { useUnreadCounts } from "../services/rpc_supabase/notifications";

const useSessionRole = () => {
  const { sessionRole } = useContext(UserSession);
  return sessionRole;
};

const NotificationContent = memo(({ data }) => {
  const messages = useMemo(() => {
    const messageCount = data?.message > 0 ? `${data.message} ${data.message > 1 ? "messages" : "message"}` : null;
    const visitCount = data?.visit > 0 ? `${data.visit} ${data.visit > 1 ? "visites" : "visite"}` : null;
    const flashCount = data?.flash > 0 ? `${data.flash} ${data.flash > 1 ? "flashes" : "flash"}` : null;

    return { messageCount, visitCount, flashCount };
  }, [data]);

  return (
    <Content>
      {messages.messageCount && <StatusLight variant="info">{messages.messageCount}</StatusLight>}
      {messages.visitCount && <StatusLight variant="info">{messages.visitCount}</StatusLight>}
      {messages.flashCount && <StatusLight variant="info">{messages.flashCount}</StatusLight>}
    </Content>
  );
});

const Notification = memo(({ data, isLoading, error }) => {
  const buttonStyle = !data?.message && !data?.visit && !data?.flash ? "outline" : "fill";

  return (
    <DialogTrigger type="tray">
      <Button
        isPending={isLoading}
        isDisabled={error}
        variant="accent"
        style={buttonStyle}
        UNSAFE_style={{
          transform: "rotate(-2deg)",
          borderRadius: "20% 25% 20% 24%",
        }}
      >
        <PushNotification />
      </Button>
      <Dialog>
        <Heading>Mes notifications</Heading>
        <Divider />
        <NotificationContent data={data} />
      </Dialog>
    </DialogTrigger>
  );
});

const Nav = () => {
  const sessionRole = useSessionRole();
  const { data, error, isLoading } = useUnreadCounts();

  return (
    <Header height="size-700" top="static-size-0" zIndex="5" position="sticky">
      <View backgroundColor="gray-50" height="size-700">
        <Flex justifyContent="space-between" marginX="size-100" alignItems="center">
          <Flex gap="size-200" alignItems="center" justifyContent="left" height="size-700">
            <NavItems />
            <TramwayAppIcon />
          </Flex>
          {sessionRole !== "authenticated" ? (
            <LoginBtn />
          ) : (
            <Notification data={data} isLoading={isLoading} error={error} />
          )}
        </Flex>
      </View>
    </Header>
  );
};

export default Nav;
