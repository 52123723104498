import { supabase } from "../../../supabaseClient";

// Fonction pour mettre à jour les préférences utilisateur via RPC
export const updateUserPreferences = async (userId, statusesArray) => {
  const { error } = await supabase.rpc("update_user_push_notification_preferences", {
    p_user_id: userId,
    statuses: statusesArray,
  });

  if (error) {
    throw new Error(`Erreur lors de la mise à jour des préférences : ${error.message}`);
  }

  return true;
};
