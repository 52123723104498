import React, { useState, useEffect, useCallback } from "react";
import { supabase } from "../supabaseClient";
import { useNotification } from "use-notification";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import { Dialog, DialogContainer, Content, Heading, Divider, Text, Flex } from "@adobe/react-spectrum";
import Teapot from "@spectrum-icons/workflow/Teapot";
function Notifications() {
  const { permission, requestPermission, notify } = useNotification();
  const { width, height } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [confettiParams, setConfettiParams] = useState(null);
  const triggerConfetti = useCallback(() => {
    setConfettiParams({
      confettiSource: {
        x: width / 2 - 100, // Centre horizontal
        y: height - 100, // Position en bas de l'écran
        w: 200,
        h: 200,
      },
      numberOfPieces: 200,
      width: width,
      height: height,
      friction: 1, // Ralentit les confettis de manière réaliste
      wind: 0, // Pas de vent horizontal
      gravity: 0.3, // Les confettis tombent doucement
      initialVelocityX: { min: -5, max: 5 }, // Légèrement dispersé horizontalement
      initialVelocityY: { min: -20, max: -10 }, // Monte verticalement avec un effet explosif
      colors: [
        "#f44336",
        "#e91e63",
        "#9c27b0",
        "#673ab7",
        "#3f51b5",
        "#2196f3",
        "#03a9f4",
        "#00bcd4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFEB3B",
        "#FFC107",
        "#FF9800",
        "#FF5722",
        "#795548",
      ],
      recycle: false,
      opacity: 1,
    });

    // Arrête les confettis après un délai
    setTimeout(() => setConfettiParams(null), 5000);
  }, [width, height]);

  const showNotification = useCallback(
    (tag, flashCount, messageCount, visitCount, createdAt) => {
      let title = "";
      let options = {
        lang: "FR",
        timestamp: new Date(createdAt).getTime(),
        icon: "./android-chrome-96x96.png",
        body: "",
        tag,
        data: { url: "tramway.life" },
      };

      switch (tag) {
        case "flash":
          title = "Flash";
          options = {
            ...options,
            body: `❤️ Tu as reçu ${flashCount} Flash !`,
            data: { ...options.data, url: "tramway.life/likedYou" },
          };
          setOpen(true);
          triggerConfetti();
          break;

        case "message":
          title = "Courrier";
          options = {
            ...options,
            vibrate: [500, 200, 500],
            body: `Tu as reçu du nouveau 💌 courrier (${messageCount})`,
            data: { ...options.data, url: "tramway.life/messages" },
          };
          break;

        case "visit":
          title = "Visite";
          options = {
            ...options,
            body: `🛎️ Tu as reçu de la visite (${visitCount})`,
            data: { ...options.data, url: "tramway.life" },
          };
          break;

        default:
          title = "Autres";
          options = {
            ...options,
            body: "You have a new notification.",
            data: { ...options.data, url: "tramway.life" },
          };
          break;
      }

      if ("serviceWorker" in navigator && Notification.permission === "granted") {
        navigator.serviceWorker.ready.then((registration) => {
          registration.showNotification(title, options);
        });
      } else if (permission === "default") {
        requestPermission().then((result) => {
          if (result === "granted") {
            notify(title, options);
          }
        });
      } else if (permission === "granted") {
        notify(title, options);
      }
    },
    [permission, requestPermission, notify, triggerConfetti],
  );

  const handleNotificationPayload = useCallback(
    (payload) => {
      const { tag, flash_count, message_count, visit_count, created_at } = payload.new;
      showNotification(tag, flash_count, message_count, visit_count, created_at);
    },
    [showNotification],
  );

  useEffect(() => {
    const channel = supabase
      .channel("notifications")
      .on("postgres_changes", { event: "*", schema: "public", table: "notifications" }, (payload) => {
        handleNotificationPayload(payload);
      })
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [handleNotificationPayload]);

  return (
    <>
      {confettiParams && open && (
        <>
          <DialogContainer modal isDismissable onDismiss={() => setOpen(false)}>
            <Dialog>
              <Content>
                <Heading>❤️ Tu as reçu 1 Flash !</Heading>
                <Divider marginBottom="size-250" />
                <Flex pading="size-250" direction="column" alignItems="center" justifyContent="center">
                  <Text lang="en-GB">
                    ...Just tea for two, <br />
                    and two for tea, <br />
                    Just me for you and you for me
                  </Text>
                  <Teapot aria-label="Tea for two" size="L" color="" />
                </Flex>

                <Confetti
                  width={confettiParams.width}
                  height={confettiParams.height}
                  numberOfPieces={confettiParams.numberOfPieces}
                  confettiSource={confettiParams.confettiSource}
                  friction={confettiParams.friction}
                  wind={confettiParams.wind}
                  gravity={confettiParams.gravity}
                  initialVelocityX={confettiParams.initialVelocityX}
                  initialVelocityY={confettiParams.initialVelocityY}
                  colors={confettiParams.colors}
                  recycle={confettiParams.recycle}
                  opacity={confettiParams.opacity}
                />
              </Content>
            </Dialog>
          </DialogContainer>
        </>
      )}
    </>
  );
}

export default Notifications;
