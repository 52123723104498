import { useState } from "react";
import { updateUserPreferences } from "./updateUserPreferences";

export const useUpdateUserPreferences = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updatePreferences = async (userId, statusesArray) => {
    setIsLoading(true);
    setError(null);

    try {
      await updateUserPreferences(userId, statusesArray);
    } catch (err) {
      setError(err.message);
      console.error("Erreur lors de la mise à jour des préférences :", err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    updatePreferences,
    isLoading,
    error,
  };
};
