import { useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import BottomNavItems from "./BottomNavItems";
import UserSession from "../contexts/UserSession";
import { BOTTOM_NAV_ITEMS } from "../constants/footer_items";

import { Grid, View, StatusLight, Flex } from "@adobe/react-spectrum";
import { useUnreadCounts } from "../services/rpc_supabase/notifications";

const BottomNav = () => {
  const memoizedBottomNavItems = useMemo(() => BOTTOM_NAV_ITEMS, []);
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const { sessionRole } = useContext(UserSession);
  const { data, error, isLoading } = useUnreadCounts();

  return (
    <View backgroundColor="gray-50" height="size-600" bottom="size-0" zIndex="1000" position="fixed" width="100%">
      <Grid width="100%" areas={["1 2 3 4"]} justifyContent="space-evenly">
        {memoizedBottomNavItems.map((item) => {
          return (
            <Flex key={uuidv4()} justifyContent="space-between" position="relative">
              <BottomNavItems item={item} navigate={navigate} pathName={pathName} sessionRole={sessionRole} />

              {!isLoading && !error && item?.label !== "Cercles" && (
                <>
                  {item?.label === "Accueil" && data?.visit > 0 && (
                    <StatusLight
                      role="status"
                      aria-label={`Notification de visites : ${item?.label}`}
                      isHidden={data.visit === 0 || pathName === item.to}
                      zIndex="-1"
                      variant="info"
                      margin="size-0"
                      position="absolute"
                      right={0}
                    ></StatusLight>
                  )}

                  {item?.label === "Courrier" && data?.message > 0 && (
                    <StatusLight
                      role="status"
                      aria-label={`Notification de messages : ${item?.label}`}
                      isHidden={data.message === 0 || pathName === item.to}
                      zIndex="-1"
                      variant="info"
                      margin="size-0"
                      position="absolute"
                      right={0}
                    ></StatusLight>
                  )}

                  {item?.label === "Flash" && data?.flash > 0 && (
                    <StatusLight
                      role="status"
                      aria-label={`Notification de flashes : ${item?.label}`}
                      isHidden={data.flash === 0 || pathName === item.to}
                      zIndex="-1"
                      variant="info"
                      margin="size-0"
                      position="absolute"
                      right={0}
                    ></StatusLight>
                  )}
                </>
              )}
            </Flex>
          );
        })}
      </Grid>
    </View>
  );
};

export default BottomNav;
