import { supabase } from "../../../supabaseClient";

// Fonction pour récupérer les comptes non lus via RPC
export const fetchUnreadCounts = async () => {
  try {
    const { data, error } = await supabase.rpc("get_unread_counts");

    if (error) {
      throw new Error(`Error fetching unread counts: ${error.message}`);
    }

    return data;
  } catch (err) {
    console.error(err);
    throw new Error(`Failed to fetch unread counts: ${err.message}`);
  }
};
